import Phaser from "phaser";
import LoadingScene from "@/games/Cooking-2/scenes/LoadingScene";
import QuestionScene from "@/games/Cooking-2/scenes/QuestionScene";
import PlayScene from "@/games/Cooking-2/scenes/PlayScene";
import AnsCorrectPlayScene from "@/games/Cooking-2/scenes/AnsCorrectPlayScene";
import AnsWrongPlayScene from "@/games/Cooking-2/scenes/AnsWrongPlayScene";
import BossScene from "@/games/Cooking-2/scenes/BossScene";
import LandingScene from "@/games/Cooking-2/scenes/LandingScene";
import Settings from "@/games/Cooking-2/scenes/Settings";

function launch(containerId) {
  return new Phaser.Game({
    type: Phaser.AUTO,
    backgroundColor: 0xf5f5f5,
    parent: containerId,
    scale: {
      parent: containerId,
      mode: Phaser.Scale.FIT,
      autoCenter: Phaser.Scale.CENTER_BOTH,
      width: 480,
      height: 854,
    },
    physics: {
      default: "arcade",
    },
    dom: {
      createContainer: true,
    },
    scene: [
      LoadingScene,
      QuestionScene,
      PlayScene,
      AnsCorrectPlayScene,
      AnsWrongPlayScene,
      BossScene,
      Settings,
      LandingScene,
    ],
  });
}

export default launch;
export { launch };
