import { Scene } from "phaser";
import i18n from "@/plugins/i18n";
import store from "@/store";
import router from "@/router";
import _ from "lodash";

export default class LandingScene extends Scene {
  constructor() {
    super({ key: "LandingScene" });
  }

  init(data) {
    this.avatar = data.avatar;
    this.name = data.name;
    this.questions = data.questions;
    this.friend = data.friend;
    this.noOfRounds = data.noOfRounds;
    this.difficulty = data.difficulty;
  }

  selectedOption = null;

  create() {
    this._backgroundImage = this.add
      .image(0, 0, "imgBackgroundX")
      .setDisplaySize(480, 854)
      .setOrigin(0);
    this._gameTitle = this.add.image(240, 320, "imgGameTitle").setScale(0.25);

    if (
      store.state.saveGame.data &&
      this.questions[0].key == store.state.saveGame.data.questions[0].key
    ) {
      this.bgSound = this.sound.add("bgm");
      this.bgSound.setVolume(
        store.state.settings.data.audio.music *
          store.state.settings.data.audio.master
      );
      this.bgSound.loop = true;

      this._continueBtn = this.add
        .image(240, 470, "imgOptionBtn")
        .setScale(0.35)
        .setInteractive({ useHandCursor: true })
        .on(
          "pointerdown",
          () => {
            this._backgroundImage.visible = false;
            this._gameTitle.visible = false;
            this._continueBtn.visible = false;
            this._continueTxt.visible = false;
            this._newBtn.visible = false;
            this._newTxt.visible = false;
            this.bgSound.play();
            this.scene.launch("PlayScene", store.state.saveGame.data);
          },
          this
        );
      this._continueTxt = this.add
        .text(240, 470, i18n.t("miniGames.general.resume"), {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 20,
          color: "#000",
          align: "center",
        })
        .setOrigin(0.5);

      this._newBtn = this.add
        .image(240, 540, "imgOptionBtn")
        .setScale(0.35)
        .setInteractive({ useHandCursor: true })
        .on(
          "pointerdown",
          () => {
            this._backgroundImage.visible = false;
            this._gameTitle.visible = false;
            this._continueBtn.visible = false;
            this._continueTxt.visible = false;
            this._newBtn.visible = false;
            this._newTxt.visible = false;
            this.bgSound.play();

            store.dispatch("resetSaveGame");
            this.scene.launch("PlayScene", {
              forceFriend: this.selectedOption == 1 ? true : false,
              noOfRounds: this.noOfRounds,
              score: 0,
              round: 0,
              avatar: this.avatar,
              name: this.name,
              questions: this.questions,
              friend: this.friend,
              difficulty: this.difficulty,
            });
          },
          this
        );
      this._newTxt = this.add
        .text(240, 540, i18n.t("miniGames.general.newGame"), {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 20,
          color: "#000",
          align: "center",
        })
        .setOrigin(0.5);

      this.tweens.add({
        targets: this._continueBtn,
        scaleX: 0.37,
        scaleY: 0.37,
        ease: "Linear",
        duration: 500,
        yoyo: true,
        repeat: -1,
      });

      this.tweens.add({
        targets: this._continueTxt,
        scaleX: 1.05,
        scaleY: 1.05,
        ease: "Linear",
        duration: 500,
        yoyo: true,
        repeat: -1,
      });
    } else {
      this._continueBtn = this.add
        .image(240, 450, "imgOptionBtn")
        .setScale(0.35);
      this._continueTxt = this.add.text(
        240,
        450,
        i18n.t("miniGames.general.continue"),
        {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 20,
          color: "#000",
          align: "center",
        }
      );

      this.tweens.add({
        targets: this._continueBtn,
        scaleX: 0.38,
        scaleY: 0.38,
        ease: "Linear",
        duration: 500,
        yoyo: true,
        repeat: -1,
      });

      this.tweens.add({
        targets: this._continueTxt,
        scaleX: 1.1,
        scaleY: 1.1,
        ease: "Linear",
        duration: 500,
        yoyo: true,
        repeat: -1,
      });

      this.bgSound = this.sound.add("bgm");
      this.bgSound.setVolume(
        store.state.settings.data.audio.music *
          store.state.settings.data.audio.master
      );
      this.bgSound.loop = true;

      if (store.state.settings.data.debug) {
        this.debug_bg = this.add
          .graphics()
          .fillStyle(0x000000, 1)
          .fillRect(130, 492, 220, 30);
        this.debug_dot = this.add.circle(160, 508, 5, 0xff0000);
        this.debug_txt = this.add.text(180, 500, "DEBUG OPTIONS", {
          fontFamily: "Sassoon, sans-serif",
          fontSize: 15,
          color: "#fff",
        });
        this.radioGroup = this.add.group();
        this.selectedOption = 0;
        let options = [
          "Normal round",
          "Force friend",
          "Auto Play",
          "Skip 1st minion",
          "Skip 2st minion",
        ];

        for (let i = 0; i < options.length; i++) {
          let radioButton = this.add.circle(150, 550 + i * 40, 10, 0xffffff);
          if (i == this.selectedOption) {
            radioButton.setFillStyle(0xfce303);
          }
          radioButton.setInteractive();
          radioButton.on("pointerdown", () => {
            this.radioGroup.children.iterate(function (radio) {
              radio.setFillStyle(0xffffff);
            });
            radioButton.setFillStyle(0xfce303);
            this.selectedOption = i;
          });
          radioButton.lableTxt = this.add.text(170, 540 + i * 40, options[i], {
            fontFamily: "Sassoon, sans-serif",
            fontSize: 15,
            color: "#fff",
            stroke: "#000",
            strokeThickness: 2,
          });
          this.radioGroup.add(radioButton);
        }
      }

      this._continueTxt.on(
        "pointerdown",
        () => {
          if (this.selectedOption == 2) {
            let score = Math.floor(Math.random() * (1000 - 900 + 1)) + 900;
            this.event_data = _.cloneDeep(store.state.event.data);
            this.event_data.submission.countCorrect =
              Math.floor(Math.random() * (10 - 7 + 1)) + 7;
            this.event_data.submission.accuracy = score / 10;
            this.event_data.submission.star = 3;
            this.event_data.submission.score = score;
            this.event_data.submission.submission += 1;
            this.event_data.boss.isCollected = true;
            this.event_data.record.attempt += 1;
            this.event_data.difficulty = this.difficulty;
            this.event_data.record.submission += 1;
            this.event_data.record.topScoreOld =
              this.event_data.record.topScore;
            this.event_data.record.topStarOld = this.event_data.record.topStar;
            if (score > this.event_data.record.topScore) {
              this.event_data.record.topScore = score;
              this.event_data.record.topStar = 3;
            }

            this.event_data.gameLoot = Math.floor(
              Math.random() * (5 - 3 + 1) + 3
            );
            store.commit("updateEvent", this.event_data);

            this.time.delayedCall(100, () => {
              router.push({
                name: "PageEventGameScore",
                params: {
                  id: store.state.event.data.record.levelId,
                },
              });
            });
          } else {
            this._backgroundImage.visible = false;
            this._gameTitle.visible = false;
            this._continueBtn.visible = false;
            this._continueTxt.visible = false;

            if (store.state.settings.data.debug) {
              this.radioGroup.children.entries.forEach((element) => {
                element.visible = false;
                element.lableTxt.visible = false;
              });
              this.debug_bg.visible = false;
              this.debug_txt.visible = false;
              this.debug_dot.visible = false;
            }
            this.bgSound.play();

            this.scene.launch("PlayScene", {
              forceFriend: this.selectedOption == 1 ? true : false,
              noOfRounds: this.noOfRounds,
              score: 0,
              round:
                this.selectedOption == 3 || this.selectedOption == 4 ? 1 : 0,
              qCount: 0,
              avatar: this.avatar,
              name: this.name,
              questions: this.questions,
              friend: this.friend,
              difficulty: this.difficulty,
              countCorrect: 0,
              enemyCount:
                this.selectedOption == 3 ? 2 : this.selectedOption == 4 ? 1 : 3,
            });
          }
        },
        this
      );
      this._continueTxt
        .setOrigin(0.5)
        .setPadding(10)
        .setInteractive({ useHandCursor: true })
        .setFixedSize(200, 40);
    }
  }

  update() {
    this.bgSound.setVolume(
      store.state.settings.data.audio.music *
        store.state.settings.data.audio.master
    );
  }
}
