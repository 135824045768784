<template>
  <!-- <transition name="fade" mode="out-in"> -->
  <div class="screen-wrap">
    <v-app class="hmc-app">
      <v-main class="main-body">
        <v-container class="app-body">
          <!-- BOC:[error] -->
          <ApiErrorDialog v-if="recordApi.isError" :api="recordApi" />
          <ApiErrorDialog v-if="inventoryApi.isError" :api="inventoryApi" />
          <ApiErrorDialog v-if="PlayerApi.isError" :api="PlayerApi" />
          <ApiErrorDialog v-if="XpApi.isError" :api="XpApi" />
          <!-- EOC -->
          <FinishedBanner
            v-if="finished"
            :message="
              this.recordApi.isLoading
                ? 'saving record...'
                : this.inventoryApi.isLoading
                ? 'saving inventory...'
                : this.PlayerApi.isLoading
                ? 'saving player data...'
                : 'saved'
            "
          ></FinishedBanner>
          <CompletionGift
            v-if="showReward"
            :callback="showScoreRe"
            :callbackClose="callbackClose"
          />
          <Score
            v-if="showScore"
            :difficulty="difficulty"
            :score="score"
            :star="event.submission.star"
            :bossIsCollected="event.boss.isCollected"
            :bossImg="event.boss.img"
            :bossName="event.boss.name"
            playAgain="PageMiningEnter"
          ></Score>
          <XPDialog
            v-if="showXP && showScore"
            :callbackClose="() => (showXP = false)"
            :showDialog="true"
            :details="xpDetails"
          />
        </v-container>
      </v-main>
    </v-app>
  </div>
  <!-- </transition> -->
</template>
    
    <script>
import { mapState } from "vuex";
export default {
  components: {
    FinishedBanner: () =>
      import(
        /* webpackChunkName: "component-mini-game-finished-banner" */ "@/components/games/FinishedBanner"
      ),
    CompletionGift: () =>
      import(
        /* webpackChunkName: "component-game-loot" */ "@/components/games/GameLoot"
      ),
    Score: () =>
      import(
        /* webpackChunkName: "component-mini-game-score" */ "@/components/games/Score"
      ),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    XPDialog: () =>
      import(
        /* webpackChunkName: "component-level-up-dialog" */ "@/components/XPDialog.vue"
      ),
  },
  computed: mapState({
    settings: (state) => state.settings.data,
    event: (state) => state.event.data,
    boss: (state) => state.boss.data,
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    showReward: false,
    finished: true,
    showScore: false,
    score: 0,
    difficulty: 1,
    event_data: null,
    submissionApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    recordApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    inventoryApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    PlayerApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    XpApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    schoolYear: null,
    showXP: false,
    xpDetails: {},
  }),
  created() {},
  mounted() {
    this.$store.dispatch("resetSaveGame");
    this.$store.dispatch("resetGameCache");
    if (this.auth.Classroom) {
      this.schoolYear = this.auth.Classroom.schoolYear;
    } else {
      this.schoolYear = this.auth.User.schoolYear;
    }
    if (this.event.gameLoot) {
      if (this.event.api.submission) {
        if (this.settings.debug) {
          if (this.event.data_sub) {
            this.submission();
          }
        } else {
          this.submission();
        }
      }
      if (this.event.api.record) {
        this.record();
      } else {
        if (this.event.api.player) {
          this.savePlayer();
        }
      }

      if (this.event.boss.isCollected) {
        if (this.event.api.inventory) {
          this.inventory();
        }
      }
      this.score = this.event.submission.score;
      this.difficulty = this.event.difficulty;
      if (
        !this.recordApi.isLoading &&
        !this.inventoryApi.isLoading &&
        !this.PlayerApi.isLoading
      ) {
        this.showReward = true;
      }
    } else {
      this.$router.push({
        name: "PageEventLevels",
      });
    }
  },
  methods: {
    showScoreRe() {
      this.event_data = this.$_.cloneDeep(this.event);
      this.event_data.gameLoot = null;
      this.$store.commit("updateEvent", this.event_data);
    },
    callbackClose() {
      this.showReward = false;
      this.finished = false;
      this.showScore = true;
    },
    next() {
      this.$router.push({
        name: "PageEventGameScore",
        params: {
          id: this.$route.params.id,
        },
      });
    },
    async updateXP() {
      this.XpApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/xp/save";

      this.XpApi.callbackReset = () => {
        this.XpApi.isLoading = true;
        this.XpApi.isError = false;
      };

      this.XpApi.callbackError = (e) => {
        this.XpApi.isLoading = false;
        this.errorMessage = e;
        this.error = true;
      };
      this.XpApi.callbackSuccess = async (resp) => {
        // if (resp.isLevelUp) {
        this.showXP = true;
        this.xpDetails = resp;
        // }
        this.XpApi.isLoading = false;
      };
      this.XpApi.params = {
        activityKey: "EVENT_LEVEL",
      };
      await this.$api.fetch(this.XpApi);
    },
    submission() {
      this.submissionApi.url =
        this.$api.servers.log +
        "/api/v1/" +
        this.$_getLocale() +
        "/submission/add";
      this.submissionApi.callbackReset = () => {
        this.submissionApi.isLoading = true;
        this.submissionApi.isError = false;
        this.submissionApi.error = null;
      };
      this.submissionApi.callbackError = (e) => {
        this.submissionApi.isLoading = false;
        this.submissionApi.isError = true;
        this.submissionApi.error = e;
      };
      this.submissionApi.callbackSuccess = () => {
        this.submissionApi.isLoading = false;
      };
      this.submissionApi.params = this.event.submission;
      this.$api.fetch(this.submissionApi);
    },
    record() {
      this.recordApi.url =
        this.$api.servers.event +
        "/api/v2/" +
        this.$_getLocale() +
        "/game/event/record/save";
      this.recordApi.callbackReset = () => {
        this.recordApi.isLoading = true;
        this.recordApi.isError = false;
        this.recordApi.error = null;
      };
      this.recordApi.callbackError = (e) => {
        this.recordApi.isLoading = false;
        this.recordApi.isError = true;
        this.recordApi.error = e;
      };
      this.recordApi.callbackSuccess = () => {
        this.recordApi.isLoading = false;
        if (
          !this.recordApi.isLoading &&
          !this.inventoryApi.isLoading &&
          !this.PlayerApi.isLoading
        ) {
          this.showReward = true;
          let _event_data = this.$_.cloneDeep(this.event);
          _event_data.api.inventory = false;
          _event_data.api.record = false;
          _event_data.api.player = false;
          _event_data.api.submission = false;
          this.$store.commit("updateEvent", _event_data);
        }
        this.savePlayer();
      };
      this.recordApi.params = this.event.record;
      this.$api.fetch(this.recordApi);
    },
    inventory() {
      this.inventoryApi.url =
        this.$api.servers.game +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/item/updateMany";
      this.inventoryApi.callbackReset = () => {
        this.inventoryApi.isLoading = true;
        this.inventoryApi.isError = false;
      };

      this.inventoryApi.callbackError = (e) => {
        this.inventoryApi.isLoading = false;
        this.inventoryApi.isError = true;
        this.inventoryApi.error = e;
      };

      this.inventoryApi.callbackSuccess = () => {
        this.inventoryApi.isLoading = false;
        if (
          !this.recordApi.isLoading &&
          !this.inventoryApi.isLoading &&
          !this.PlayerApi.isLoading
        ) {
          this.showReward = true;
          let _event_data = this.$_.cloneDeep(this.event);
          _event_data.api.inventory = false;
          _event_data.api.record = false;
          _event_data.api.player = false;
          _event_data.api.submission = false;
          this.$store.commit("updateEvent", _event_data);
        }
      };
      this.inventoryApi.params = {
        data:
          '[{"action":"add","itemKey":"' +
          this.event.boss.key +
          '","quantity": 1}]',
      };
      this.$api.fetch(this.inventoryApi);
    },
    async savePlayer() {
      await this.updateXP();
      this.event_data = this.$_.cloneDeep(this.event);
      this.PlayerApi.url =
        this.$api.servers.event +
        "/api/v2/" +
        this.$_getLocale() +
        "/game/event/player/save";

      this.PlayerApi.callbackReset = () => {
        this.PlayerApi.isLoading = true;
        this.PlayerApi.isError = false;
      };

      this.PlayerApi.callbackError = (e) => {
        this.PlayerApi.isLoading = false;
        this.PlayerApi.isError = true;
        this.PlayerApi.error = e;
      };
      this.PlayerApi.callbackSuccess = (resp) => {
        this.PlayerApi.isLoading = false;
        this.event_data.Save = resp.Save;
        this.event_data.Player = resp.Player;
        this.$store.commit("updateEvent", this.event_data);
        if (
          !this.recordApi.isLoading &&
          !this.inventoryApi.isLoading &&
          !this.PlayerApi.isLoading
        ) {
          this.showReward = true;
          let _event_data = this.$_.cloneDeep(this.event);
          _event_data.api.inventory = false;
          _event_data.api.record = false;
          _event_data.api.player = false;
          _event_data.api.submission = false;
          this.$store.commit("updateEvent", _event_data);
        }
      };
      let _data = JSON.parse(this.event_data.Save.data);
      _data.token -= 1;
      this.event_data.Save.data = JSON.stringify(_data);
      this.PlayerApi.params = {
        eventCode: this.$route.params.code,
        version: this.event_data.Save.version
          ? this.event_data.Save.version
          : "1.0",
        build: this.event_data.Save.build + 1,
        data: this.event_data.Save.data,
        totalStar:
          this.event_data.Player.totalStar +
          this.event.record.topStar -
          this.event.record.topStarOld,
        totalScore:
          this.event_data.Player.totalScore +
          this.event.record.topScore -
          this.event.record.topScoreOld,
        totalAttempt: this.event_data.Player.totalAttempt + 1,
        totalSubmission: this.event_data.Player.totalSubmission + 1,
        progress:
          (this.event_data.Player.totalStar +
            this.event.record.topStar -
            this.event.record.topStarOld) /
          (this.boss.length * 3),
        schoolYear: this.schoolYear,
      };
      this.$api.fetch(this.PlayerApi);
    },
  },
};
</script>

    <style scoped>
.app-body {
  max-width: 480px;
  min-height: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #f5dfc5; */
}

.main-body {
  /* background-color: #f5dfc5; */
  background-color: rgba(214, 132, 49, 0.3);
}
</style>